import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import {
  IcoMenuChevronDown,
  IconMessageOff,
  IconMessageOn,
  IconOutsourcingOnUrl
} from '@src/resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { Fragment, useLayoutEffect, useMemo, useRef } from 'react';

import {
  getLanguageSubTaskStatusTypeLabel,
  getStatusLabel,
  getStatusText,
  getTaskStatusLabelColor,
  getWorkerTypeLabel
} from '@src/common/config/Code';
import { renderAvatar, renderDateTime } from '../utils/render';

const defaultUserObj = (workerType: string) => ({
  avatarImgUrl: '',
  endDateTime: null,
  name: '',
  slackId: '',
  startDateTime: null,
  status: 'ACTIVE',
  userId: 0,
  workerStatus: 'NEW',
  workerType
});

export default function Row4LanguageSubTaskItem({ item, taskType }: any) {
  const refTr = useRef<HTMLTableRowElement>(null);

  const workerList = useMemo(() => {
    if (!Array.isArray(item?.workerList)) return [];
    const e0 =
      item.workerList.find((e: any) => e?.workerType === 'TRANSLATOR') ??
      defaultUserObj('TRANSLATOR');
    const e1 =
      item.workerList.find((e: any) => e?.workerType === 'FIRST_CHECKER') ??
      defaultUserObj('FIRST_CHECKER');
    const e2 =
      item.workerList.find((e: any) => e?.workerType === 'SECOND_CHECKER') ??
      defaultUserObj('SECOND_CHECKER');

    return [e0, e1, e2].filter((e) => e);
  }, [item]);

  useLayoutEffect(() => {
    let el: any = refTr.current;
    if (el) {
      let hasNext = false;
      let next;
      let i = 0;
      while ((next = el.nextElementSibling)) {
        if ((hasNext = next.classList.contains('row-3-item'))) break;
        if (++i > 10) {
          break;
        }
      }
      if (hasNext) el.classList.add('has-next-row-3-item');
      // prev
      while ((el = el.previousElementSibling as any)) {
        if (
          Array.from(el.classList).some((e) =>
            /Row4LanguageSubTaskItemWrap/.test(e as string)
          )
        ) {
          el.classList.add('has-next-row-3-item');
          break;
        }
      }
    }
  });

  const progressRate = useMemo(() => {
    if (!item?.isBoracatUseOrNot) return 0;
    let v: number = item?.completeAmount / item?.netAmount;
    if (isNaN(v)) return 0;
    return (100 * v).toFixed(1);
  }, [item]);

  return (
    <Row4LanguageSubTaskItemWrap
      data-language-sub-task-id={item.languageSubTaskId}
      data-language-sub-task-key={item.languageSubTaskKey}
      ref={refTr}
    >
      <td colSpan={11}>
        <div className="worker-process">
          {workerList.map((worker: any, i: number) => {
            const {
              userId,
              slackId,
              status,
              workerType,
              workerStatus,
              name,
              avatarImgUrl,
              grayScale,
              brightness,
              ...other
            } = worker;
            const statusLabel = getLanguageSubTaskStatusTypeLabel(workerStatus);
            const statusColor = getTaskStatusLabelColor(workerStatus);
            const workerTypeLabel = getWorkerTypeLabel(workerType);
            return (
              <Fragment key={i}>
                {i !== 0 && (
                  <IcoMenuChevronDown
                    style={{
                      fill: '#d9d9d9',
                      transform: 'rotate(-90deg)'
                    }}
                  />
                )}
                <div
                  className={cx({
                    worker: true,
                    'current-process': workerType === item.progressingWork
                  })}
                  data-worker-type={worker.workerType}
                  data-worker-status={worker.workerStatus}
                >
                  <div className="status">
                    {userId ? (
                      <>
                        <div
                          style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '1px',
                            backgroundColor: statusColor
                          }}
                        />
                        <div>{statusLabel}</div>
                      </>
                    ) : (
                      '미배정'
                    )}
                  </div>
                  <div className="avatar">{renderAvatar(worker)}</div>
                  <div className="worker-type">
                    {worker.isOutsourceOrNot && <span>외부</span>}
                    <p>{workerTypeLabel}</p>
                  </div>
                  <div className="times">
                    <div className="label">
                      {worker.startDateTime ? (
                        <>
                          <div>작업 시작</div>
                          {renderDateTime(worker.startDateTime, false)}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="label">
                      {worker.endDateTime ? (
                        <>
                          <div>작업 종료</div>
                          {renderDateTime(
                            worker.endDateTime,
                            false // workerStatus !== 'COMPLETE'
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </td>
      <td
        colSpan={4}
        data-is-boracat-use-or-not={item.isBoracatUseOrNot}
        data-complete-amount={item.completeAmount}
        data-net-amount={item.netAmount}
      >
        <div className="amount">
          <div className="amount-progress">
            <div
              style={{
                width: '98px'
              }}
            >
              {item.isBoracatUseOrNot
                ? `진행율 (${progressRate}%)`
                : `진행율(외부 CAT)`}
            </div>
            작업 분량{' '}
            {taskType === 'LQA'
              ? `${Math.floor(Number(item.netAmount) / 250)}시간 ${
                  Number(item.netAmount) % 250 > 0 ? '30' : '00'
                }분`
              : `${item.netAmount}`}
          </div>
          {item.memo === '' ? (
            <span className="message-tooltip">
              <IconMessageOff width={14} height={14} />
            </span>
          ) : (
            <span className="message-tooltip">
              <SimpleTooltip message={item.memo} position="left">
                <IconMessageOn width={14} height={14} />
              </SimpleTooltip>
            </span>
          )}
        </div>
      </td>
    </Row4LanguageSubTaskItemWrap>
  );
}

const Row4LanguageSubTaskItemWrap = styled.tr`
  background-color: #f4f4f4;

  td > div {
    min-height: 36px;
    &:first-of-type {
      border-radius: 2px 0 0 2px;
    }
    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }

    display: flex;
    flex-direction: row;
  }

  .amount {
    margin: 0 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-progress {
      display: flex;
      flex-direction: row;
      gap: 1em;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #aaa;
    }
    .message-tooltip {
      height: 16px;
      margin-left: 20px;
    }
  }

  .worker-process {
    height: 100%;
    display: grid;
    grid-template-columns: 85px 420px 30px 420px 30px 420px;
    align-items: center;
    margin: 0 5px;
    > svg {
      margin: 0 12px;
    }

    &::before {
      content: '';
      width: 8.5px;
      height: 8.5px;
      border-left: 1px solid #cbcbcb;
      border-bottom: 1px solid #cbcbcb;
      margin-right: 6px;
      margin-left: 65px;
    }

    .worker {
      display: grid;
      grid-template-columns: auto auto 4em auto;
      align-items: center;
      .status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        /* margin-right: 10px; */
        width: 6rem;
      }
      .avatar {
        width: 40px;
        height: 30px;
        .avatar--div--wrap {
          width: 30px;
          height: 30px;
        }
      }
      .worker-type {
        display: flex;
        flex-direction: column;
        text-align: left;
        p {
          height: 20px;
        }
        span {
          margin-bottom: 0px;
          padding: 0 3px 0 10px;
          width: 30px;
          height: 13px;
          line-height: 13px;
          text-align: left;
          font-size: 10px;
          color: #9b51e0;
          background: rgba(155, 81, 224, 0.06) url(${IconOutsourcingOnUrl})
            no-repeat left 2px center;
          border-radius: 2px;
          cursor: pointer;
        }
      }
      .times {
        width: 20rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        font-size: 12px;
        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
        }
      }
      &[data-worker-status='PROGRESS'] {
        background-color: #f0e44220;
        height: 100%;
      }
    }
  }
`;
