import React, { useEffect, useRef, useState } from 'react';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconArrowRightBlack, IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box, Checkbox } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useSplitInfo } from '@src/hooks/file/splitInfo';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';
import { useAlert } from '@src/hooks/modal/useAlert';
import { TaskSplitStyle } from './TaskSplit.style';
import { TPInputCheckSingleBox } from '@src/units/input/TPInput.checkBox';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useGetTaskInfo } from '../file/getTaskInfo';
import { renderRowStatus } from '@src/pages/dashboard/utils/render';
import { LANGUAGE } from '@src/common/config/Code';
import {
  TPInputCheckBoxBasic,
  TPInputCheckSingleBoxBasic
} from '@src/units/input/TPInput.checkBoxBasic';
import Loading from '@src/pages/components/Loading';

interface IDataInfo {
  startSerial: number;
  endSerial: number;
  segmentCount: number;
  wordCount: number;
}

const TaskSplitPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { fileId } = useParams();
  const { data: taskData } = useGetTaskInfo(Number(fileId));
  const {
    data: splitList,
    isLoading,
    reDate,
    getData,
    changeData,
    putSplit,
    deleteData,
    closeModal
  } = useSplitInfo();
  const { openHandle: alertHandle } = useAlert();
  const [reSplit, setReSplit] = useState(false);
  const splitTypeList = [
    { id: 1, type: 'SEGMENT', text: '세그먼트' },
    { id: 2, type: 'WORD', text: '단어, 글자' }
  ];
  const [splitTypeOption, setSplitTypeOption] = useState(splitTypeList[0].type);
  const [segmentCount, setSegmentCount] = useState(0);
  const [segments, setSegments] = useState<JSX.Element[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [splitConfirm, setSplitConfirm] = useState(false);
  const [warningMessages, setWarningMessages] = useState({
    fileRange: {
      text: '* 파일 분량보다 등분 수가 많습니다.',
      active: false
    },
    fileMatch: {
      text: '* 파일 분량이 일치하지 않습니다.',
      active: false
    }
  });
  const [segmentRange, setSegmentRange] = useState<
    {
      order: number;
      startSerial: number;
      endSerial: number;
      wordCount: number;
      segmentCount: number;
    }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await reDate(Number(fileId));
      if (data.splitList.length > 0) {
        setSegmentRange(data.splitList);
        setSegmentCount(data.splitList.length);
        setReSplit(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (segmentCount.toString().startsWith('0')) {
      setSegmentCount(Number(segmentCount.toString().substring(1)));
    }
  }, [segmentCount]);

  const handleUpload = async () => {
    try {
      const splitInfo = {
        languageTaskId: Number(fileId),
        fileSplitRange: segmentRange.map(
          ({ order, startSerial, endSerial }) => ({
            order,
            startSerial,
            endSerial
          })
        )
      };
      // console.log('splitInfo', splitInfo);
      putSplit(splitInfo);
    } catch (error) {
      console.error('Error split file:', error);
    }
  };

  const handleApply = async () => {
    if (!splitConfirm) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
    const splitInfo = {
      languageTaskId: fileId,
      splitType: splitTypeOption,
      splitCount: segmentCount
    };
    const data = await getData(splitInfo);
    // console.log('data', data.splitList);
    setSegmentRange(data.splitList);
  };

  const handleChange = async (
    order: number,
    startSerial: number,
    endSerial: number
  ) => {
    if (!splitConfirm) return;
    const splitDetail = {
      languageTaskId: fileId,
      order: order,
      startSerial: startSerial,
      endSerial: endSerial
    };
    const data = await changeData(splitDetail);
    setDisabled(false);
    setSegmentRange(
      segmentRange.map((segment) => {
        if (segment.order === order) {
          return {
            ...segment,
            startSerial: (data as unknown as IDataInfo).startSerial,
            endSerial: (data as unknown as IDataInfo).endSerial,
            segmentCount: (data as unknown as IDataInfo).segmentCount,
            wordCount: (data as unknown as IDataInfo).wordCount
          };
        }
        return segment;
      })
    );
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const cleanedValue = e.currentTarget.value.replace(/[^0-9]/g, '');
    e.currentTarget.value = cleanedValue.replace(/^0+/, '') || '0';
    const numValue = Number(cleanedValue);
    const fileNum =
      splitTypeOption === 'WORD'
        ? taskData?.totalWordCount
        : taskData?.totalSegmentCount;

    const updateWarningAndConfirm = (isActive: boolean, zeroCheck: boolean) => {
      setWarningMessages((prev) => ({
        ...prev,
        fileRange: { ...prev.fileRange, active: isActive }
      }));
      setSplitConfirm(zeroCheck);
    };

    if (numValue > 0 && numValue <= fileNum!) {
      updateWarningAndConfirm(false, true);
    } else if (numValue > fileNum!) {
      updateWarningAndConfirm(true, false);
    } else {
      updateWarningAndConfirm(false, false);
    }
  };

  const handleRangeChange = (
    order: number,
    key: string,
    value: number,
    startSerial: number,
    endSerial: number
  ) => {
    const updatedRanges = [...segmentRange];

    if (key === 'startSerial') {
      updatedRanges[order - 1].startSerial = value;
    } else {
      updatedRanges[order - 1].endSerial = value;
    }

    setSegmentRange(updatedRanges); // 수정된 배열로 상태 업데이트
    if (value > 0 && startSerial <= endSerial) {
      if (order === 1) {
        if (
          segmentRange.length > 1 &&
          endSerial < segmentRange[order].startSerial
        ) {
          handleChange(order, startSerial, endSerial);
        } else if (segmentRange.length === 1) {
          handleChange(order, startSerial, endSerial);
        } else {
          setDisabled(true);
        }
      } else if (
        order === segmentRange.length &&
        startSerial > segmentRange[order - 2].endSerial
      ) {
        handleChange(order, startSerial, endSerial);
      } else if (
        order > 1 &&
        order < segmentRange.length &&
        startSerial > segmentRange[order - 2].endSerial &&
        endSerial < segmentRange[order].startSerial
      ) {
        handleChange(order, startSerial, endSerial);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
    // console.log(endSerial, segmentRange[order].startSerial);
  };

  const handleReset = () => {
    if (reSplit) {
      deleteData(Number(fileId));
    }
    setSegmentRange([]);
    setSegmentCount(0);
    setSplitConfirm(false);
    setDisabled(false);
    setReSplit(false);
  };

  const handleToggle = (option: string) => {
    setSplitTypeOption(option);
    setSegmentCount(0);
    setWarningMessages((prev) => ({
      ...prev,
      fileRange: { ...prev.fileRange, active: false }
    }));
    setSplitConfirm(false);
    setSegmentRange([]);
  };

  const toggleModal = (option: string) => {
    alertHandle({
      title: '분할 기준 변경',
      text: '분할 기준을 변경하겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        handleToggle(option);
      }
    });
  };

  const resetModal = () => {
    alertHandle({
      title: '범위 초기화',
      text: '입력 중인 내용이 모두 초기화됩니다. <br/>한 번 삭제된 데이터는 복구가 불가능합니다.',
      cancelLabel: '취소',
      ok: () => {
        handleReset();
        if (scrollRef.current) {
          scrollRef.current.scrollTop = 0;
        }
      }
    });
  };

  const splitModal = () => {
    alertHandle({
      title: '파일 분할 확인',
      text: '파일을 슬라이스하겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        handleUpload();
      }
    });
  };

  const handleRangeCheck = () => {
    const totalWords = segmentRange.reduce(
      (acc, segment) => acc + segment.wordCount,
      0
    );
    const totalSegments = segmentRange.reduce(
      (acc, segment) => acc + segment.segmentCount,
      0
    );
    if (
      totalWords !== taskData?.totalWordCount ||
      totalSegments !== taskData?.totalSegmentCount
    ) {
      setWarningMessages((prev) => ({
        ...prev,
        fileMatch: { ...prev.fileMatch, active: true }
      }));
    } else {
      setWarningMessages((prev) => ({
        ...prev,
        fileMatch: { ...prev.fileMatch, active: false }
      }));
    }
    console.log(totalWords, totalSegments);
  };

  useEffect(() => {
    setSegments(
      segmentRange.map((range, index) => {
        return (
          <tr key={index}>
            <td>{range.order}</td>
            <td>
              <div className="range--input--wrapper">
                <input
                  type="number"
                  value={range.startSerial}
                  onChange={(e) => {
                    handleRangeChange(
                      range.order,
                      'startSerial',
                      Number(e.target.value),
                      Number(e.target.value),
                      range.endSerial
                    );
                  }}
                  disabled={reSplit}
                />
                <span className="range--separator">-</span>
                <input
                  type="number"
                  value={range.endSerial}
                  onChange={(e) =>
                    handleRangeChange(
                      range.order,
                      'endSerial',
                      Number(e.target.value),
                      range.startSerial,
                      Number(e.target.value)
                    )
                  }
                  disabled={reSplit}
                />
              </div>
              {range.startSerial > range.endSerial && (
                <div className="warning--message">
                  * 시작 번호가 끝 번호보다 큽니다.
                </div>
              )}
              {range.order > 1 &&
                range.startSerial <=
                  segmentRange[range.order - 2].endSerial && (
                  <div className="warning--message">
                    * 시작 번호는 이전 범위보다 커야합니다.
                  </div>
                )}
            </td>
            <td>{range.segmentCount}</td>
            <td>{range.wordCount}</td>
          </tr>
        );
      })
    );
    handleRangeCheck();
  }, [segmentRange]);

  return (
    <div css={TaskSplitStyle}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div className="title">
        <TPHeadlineBasic decoration type="h2">
          작업 파일 분할
        </TPHeadlineBasic>
      </div>
      <div className="filebox">
        <div className="filebox--title">
          <span className="filebox--status">
            {renderRowStatus(taskData?.status)}
          </span>
          <p className="filebox--language">
            <span>
              {LANGUAGE.filter((v) => v.code === taskData?.startLanguage).map(
                (lang) => lang.label
              )}
            </span>
            <span className="arrow">
              <IconArrowRightBlack />
            </span>
            <span>
              {LANGUAGE.filter(
                (v) => v.code === taskData?.destinationLanguage
              ).map((lang) => lang.label)}
            </span>
          </p>
        </div>
        <div className="filebox--content">{taskData?.taskTitle}</div>
      </div>
      <div className="segment--wrap">
        <div className="modal--div--wrapper">
          <strong>작업 파일</strong>
          <div className="fileInfo">
            <span>{taskData?.fileName.split('.').pop()}</span>
            <p>{taskData?.fileName}</p>
          </div>
        </div>
        <div className="modal--div--wrapper">
          <strong>파일 분량</strong>
          <div className="file--length">
            <p>
              <span>세그먼트</span>
              {taskData?.totalSegmentCount}
            </p>
            <p>
              <span>단어, 글자 수</span>
              {taskData?.totalWordCount}
            </p>
          </div>
        </div>
        <div className="modal--div--wrapper">
          <strong>
            분할 기준<span className="required">*</span>
          </strong>
          <ul className="modal--div--checkbox">
            {splitTypeList.map((item) => (
              <li key={item.id} className={reSplit ? 'disabled' : ''}>
                <TPInputCheckBoxBasic
                  option={item.text}
                  optionKey={item.type}
                  selectOptions={[splitTypeOption]}
                  onClick={(option) => {
                    toggleModal(option);
                  }}
                  disabled={reSplit}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="modal--div--wrapper">
          <strong>
            등분<span className="required">*</span>
          </strong>
          <div className="split--option">
            <input
              type="number"
              min="1"
              value={segmentCount}
              onInput={handleInput}
              onChange={(e) => setSegmentCount(Number(e.target.value))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleApply();
                }
              }}
              disabled={reSplit}
            />
            <button
              className={`${splitConfirm ? 'active' : ''}`}
              onClick={handleApply}
              disabled={reSplit}
            >
              적용
            </button>
          </div>
          {warningMessages.fileRange.active && (
            <div className="warning--message">
              {warningMessages.fileRange.text}
            </div>
          )}
        </div>

        {segments.length > 0 && (
          <div className="modal--div--box">
            <div className="reset--wrap">
              {warningMessages.fileMatch.active && (
                <div className="warning--message">
                  {warningMessages.fileMatch.text}
                </div>
              )}
              {reSplit && (
                <p className="re--split">
                  작업 파일 재분할 시 초기화 버튼을 클릭해 주세요.
                </p>
              )}
              {segmentRange.length > 0 && (
                <button onClick={resetModal}>초기화</button>
              )}
            </div>
            <div ref={scrollRef} className="segment--table--wrapper">
              <table className="segment--table">
                <colgroup>
                  <col width="23%" />
                  <col />
                  <col width="23%" />
                  <col width="23%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>범위</th>
                    <th>세그먼트</th>
                    <th>단어, 글자</th>
                  </tr>
                </thead>
                <tbody>{segments}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={3}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="분할하기"
            fullWidth
            disabled={
              disabled ||
              warningMessages.fileMatch.active ||
              warningMessages.fileRange.active ||
              reSplit
            }
            onClick={splitModal}
            size={'large'}
          />
        </Box>
      </Box>
      {isLoading && <Loading />}
    </div>
  );
};

export default TaskSplitPage;
