import { useEffect, useState, PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

export interface LanguagePairData {
  languagePair: string;
  workerTypeList: {
    workerTypeName: string;
    workerTypePercentage: number;
    workerTypeAmount: number;
  }[];
  userAverageLoad: number;
  userAverageAmount: number;
  userTotalAmount: number;
  partAverageLoad: number;
  partAverageAmount: number;
  partTotalAmount: number;
  diffAverageLoad: number;
  diffAverageAmount: number;
  diffTotalAmount: number;
  projectList: [
    {
      projectName: string;
      projectPercent: number;
      projectAverageAmount: number;
      projectTotalAmount: number;
    }
  ];
}

export interface WorkerList {
  userId: number;
  userName: string;
  userImgUrl: string;
  userLanguagePairList: {
    startLanguage: string;
    destinationLanguage: string;
  }[];
  byLanguagePairData: LanguagePairData[];
}

interface GraphPerformanceProps {
  activeTab: { [key: number]: string };
  item: WorkerList;
  workerTypeList: {
    workerTypeName: string;
    workerTypePercentage: number;
    workerTypeAmount: number;
  }[];
}

const GraphPerformance = ({
  activeTab,
  item,
  workerTypeList
}: GraphPerformanceProps) => {
  const COLORS = ['#00C2D9', '#FF58A5', '#AD50BF'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const hasValidData = (data: typeof workerTypeList) => {
    return (
      Array.isArray(data) &&
      data.some(
        (worker) =>
          !isNaN(worker.workerTypePercentage) && worker.workerTypePercentage > 0
      )
    );
  };

  return (
    <>
      {hasValidData(workerTypeList) ? (
        <ResponsiveContainer>
          <PieChart width={200} height={200}>
            {(() => {
              const selectedData =
                item.byLanguagePairData[parseInt(activeTab[item.userId])];

              const workerTypeList = (
                selectedData?.workerTypeList || []
              ).filter((worker) => worker.workerTypePercentage > 0);

              return (
                <Pie
                  data={workerTypeList}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="workerTypePercentage"
                >
                  {workerTypeList.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              );
            })()}
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <span className="no-data">No data</span>
      )}
    </>
  );
};

export default GraphPerformance;
